
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Collapse from 'components/molecules/collapse';
import PaymentInformation from 'components/atoms/payment-information';
import { Individual, numberDescriptions, type Child } from 'constants/registration';
import { ticketPrice, Transaction, TransactionStatus } from 'constants/transaction';
import * as Constant from 'constants/registration';
import { getRegistrationByBillNo } from 'datas/registration';
import { useTransaction } from 'stores/transaction';

type PreviewIndividualProps = {
    billNo: string | null;
}

export const PreviewIndividual = (props: PreviewIndividualProps) => {
    const dataAutoReloadInterval = 30000;
    
    const navigate = useNavigate();
    const transaction = useTransaction(state => state.transaction);
    const [dataRegistration, setDataRegistration] = useState<Individual & { guardian_count: number }>();
    const [dataTransaction, setDataTransaction] = useState<Transaction>();

    const getStatusVariant = (status: TransactionStatus) => {
        let variant = 'success';

        if (status === TransactionStatus.WAITING) variant = 'warning';
        else if (status === TransactionStatus.EXPIRED) variant = 'danger';

        return variant;
    }

    const handleCopyToClipboard = () => {
        if (!props.billNo) return;

        navigator.clipboard.writeText(props.billNo)
            .then(() => {
                alert('Berhasil menyalin No Registrasi');
            });
    }

    const onLoadDataTransaction = async () => {
        try {
            if (!props.billNo) throw 'Bill no doesnt exist';

            if (!transaction?.length) throw 'Transaction doesnt exist';

            const selected = transaction.find(({ bill_no }) => bill_no === props.billNo);
            if (selected) setDataTransaction(selected);
            
            throw 'Transaction not found';
        } catch (err) {
        }
    }

    const onLoadDataRegistration = async () => {
        try {
            if (!props.billNo) throw 'Bill no doesnt exist';

            const data = await getRegistrationByBillNo({
                billNo: props.billNo,
                singleSnapshot: true
            });
            if (data) {
                if (data?.children?.length) {
                    const { guardian_count } = data.children[0];
                    data.guardian_count = guardian_count ?? 0;
                }

                setDataRegistration(data);
            }

            throw 'Data not found';
        } catch (err) {
            console.error(err);
        }
    }

    const onLoadPaymentScreen = () => {
        if (dataTransaction?.payment_url) {
            window.location.href = dataTransaction.payment_url;
        } else {
            navigate('/transaction');
        }
    }

    const columns: { key: keyof (Individual & { guardian_count: number }), label: string }[] = [
        { key: 'parent_email', label: 'Email Orang Tua/Wali' },
        { key: 'parent_phone', label: 'No HP Orang Tua/Wali' },
        { key: 'parent_domicile', label: 'Domisili' },
        { key: 'event_location', label: 'Akan mengikuti Bible Study di' },
        { key: 'guardian_count', label: 'Jumlah Pendamping yang Mendampingi' }
    ];

    const childColumns: { key: keyof Child, label: string }[] = [
        { key: 'grade', label: 'Grade Anak' },
        { key: 'gender', label: 'Gender Anak' },
        { key: 'phone', label: 'No HP Anak' },
        { key: 'pick_up_type', label: 'Penjemputan Anak' },
    ];

    const children = useMemo(() => {
        if (!dataRegistration?.children?.length) return [];

        return dataRegistration?.children.map((item) => {
            let gender = item.gender;
            let pick_up_type = item.pick_up_type;
            const selectedGender = Constant.gender.find(({ value }) => value === item.gender);
            if (selectedGender) gender = selectedGender.label;

            const selectedPickUpType = Constant.child_pick_up.find(({ value }) => value === item.pick_up_type);
            if (selectedPickUpType) pick_up_type = selectedPickUpType.label;
            return {
                ...item,
                gender,
                pick_up_type
            }
        })
    }, [dataRegistration?.children])

    const ticketNominal = useMemo(() => {
        const childCount = dataRegistration?.children?.length ?? 0;
        const guardianCount = dataRegistration?.guardian_count ?? 0;
        const nominal = (childCount + guardianCount) * ticketPrice;

        return nominal;
    }, [dataRegistration?.children, dataRegistration?.guardian_count])

    useEffect(() => {
        onLoadDataTransaction();
        onLoadDataRegistration();

        const autoReloadInterval = setInterval(() => {
            onLoadDataTransaction();
            onLoadDataRegistration();
        }, dataAutoReloadInterval);

        return () => {
            clearInterval(autoReloadInterval);
        };
    }, [])

    return dataRegistration && props.billNo ? (
        <div className='preview preview-group'>
            <Alert>
                <small className='text-muted'>No Registrasi Anda:</small>
                <span className='fw-bold'><u>{props.billNo}</u></span>
                <Button size='sm' onClick={handleCopyToClipboard}>
                    <small>SALIN</small>
                </Button>
            </Alert>

            <hr />

            <div className='preview_item'>
                <span className='preview_item_value'>
                    Data Pendaftaran Anda:
                </span>
            </div>

            {columns.map(({ key, label }) => (
                <div className='preview_item' key={`preview_individual_parent_${key}`}>
                    <small className='text-muted'>{label}</small>
                    <span className='preview_item_value'>{dataRegistration[key] as string}</span>
                </div>
            ))}

            {dataTransaction ? (
                <div className='preview_item'>
                    <small className='text-muted'>Status Pembayaran</small>
                    <span className={`fw-bold text-${getStatusVariant(dataTransaction.status)} preview_item_value`}>
                        {dataTransaction.status}
                    </span>
                </div>
            ) : null}

            {children?.map((child, index) => (
                <Collapse
                    key={`preview_individual_child_${child.name.toLowerCase()}_${index}`}
                    id={`preview_individual_child_${child.name.toLowerCase()}_${index}`}
                    defaultOpen={index < 1}
                    className='preview_collapse'
                    label={
                        <div className='preview_item'>
                            <small className='text-muted text-capitalize'>
                                Nama Lengkap Anak {numberDescriptions[index + 1] ?? index + 1}
                            </small>
                            <span className='preview_item_value'>{child.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/>
                            </svg>
                        </div>
                    }
                >
                    <React.Fragment>
                        {childColumns.map(({ key, label }) => (
                            <div className='preview_item' key={`preview_item_${key}`}>
                                <small className='text-muted'>{label}</small>
                                <span className='preview_item_value'>{child[key] as string || '-'}</span>
                            </div>
                        ))}
                    </React.Fragment>
                </Collapse>
            ))}

            {dataTransaction?.status === TransactionStatus.WAITING && (
                <div className='mb-5'>
                    <hr />
                    <PaymentInformation
                        nominal={ticketNominal}
                        className='preview_info'
                    />
                    <div className='preview_footer'>
                        <Button onClick={onLoadPaymentScreen}>
                            Lanjutkan Pembayaran
                        </Button>
                    </div>
                </div>
            )}
        </div>
    ) : null
}