import React from 'react';
import { Child } from 'constants/registration';
import { ExpanderComponentProps } from 'react-data-table-component';
import { formatDate } from 'utils/format';
import { Transaction } from 'constants/transaction';

type Data = Child & Partial<Transaction>;

export const ReportIndividualExpanded: React.FC<ExpanderComponentProps<Data>> = ({ data }) => {
    return (
        <div className="report_children">
            <span className='mb-2 fw-bold text-uppercase'>Detail Pendaftaran</span>
            <div className='report_children_payment mb-4'>
                <div>
                    <span>Email Orang Tua</span>
                    <span className='fw-bold'>{data.parent?.parent_email || '-'}</span>
                </div>
                <div>
                    <span>No Handphone Orang Tua</span>
                    <span className='fw-bold'>{data.parent?.parent_phone || '-'}</span>
                </div>
                <div>
                    <span>Domisili Orang Tua</span>
                    <span className='fw-bold'>{data.parent?.parent_domicile || '-'}</span>
                </div>
                <div>
                    <span>Kelas</span>
                    <span className='fw-bold'>{data.grade || '-'}</span>
                </div>
                <div>
                    <span>Jenis Kelamin Anak</span>
                    <span className='fw-bold'>{data.gender || '-'}</span>
                </div>
                <div>
                    <span>No Handphone Anak</span>
                    <span className='fw-bold'>{data.phone || '-'}</span>
                </div>
                <div>
                    <span>Sudah Join CG</span>
                    <span className='fw-bold'>{data.is_join_cg === 'Y' ? 'Sudah' : 'Belum'}</span>
                </div>
                <div>
                    <span>Jumlah Pendamping yang Mendampingi</span>
                    <span className='fw-bold'>{data.guardian_count ?? 0} Pendamping</span>
                </div>
                <div>
                    <span>Metode Pembayaran</span>
                    <span>{data.payment_channel_name || '-'}</span>
                </div>
                <div>
                    <span>Tanggal Tagihan</span>
                    <span>{data.bill_at ? formatDate(data.bill_at) : '-'}</span>
                </div>
                <div>
                    <span>Tanggal Pembayaran</span>
                    <span>{data.paid_at ? formatDate(data.paid_at) : '-'}</span>
                </div>
                <div>
                    <span>Batas Pembayaran</span>
                    <span>{data.expired_at ? formatDate(data.expired_at) : '-'}</span>
                </div>
            </div>
        </div>
    )
}; 