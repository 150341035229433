import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Required from 'components/atoms/form-required';
import * as Constant from 'constants/registration';
import { useRegistration } from 'stores/registration';
import FormChildren from '../form-children';

export const FormGroup = () => {
    const {
        payloadGroup: payload,
        setPayloadGroup: setPayload,
        payloadChildren,
        setPayloadChildren
    } = useRegistration();

    const onChange = (key: keyof Constant.PayloadGroup, value: string | number) => {
        const newPayload = payload ? {...payload} : {} as Constant.PayloadGroup;
        newPayload[key] = value as never;
        setPayload(newPayload);
    }

    const onChangeChildren = <K extends keyof Constant.Child>(
        key: number,
        keyField: K,
        value: Constant.Child[K]
    ) => {
        const newPayload: Constant.Child[] = [...payloadChildren];
        newPayload[key][keyField] = value;
        setPayloadChildren(newPayload);
    };

    const handleChildrenAction = (prop: {
        action: 'add' | 'remove',
        removedId?: number,
        children?: Constant.Child,
    }) => {
        const { action, removedId, children } = prop;
        const newPayload = [...payloadChildren];
        if (action === 'add') {
            newPayload.push(children as Constant.Child);
            setPayloadChildren(newPayload);
        } else if (action === 'remove' && removedId != undefined) {
            newPayload.splice(removedId, 1);
            setPayloadChildren(newPayload);
        }
    }

    return (
        <Form.Group>
            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Nama Sekolah/Rombongan <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.school_name ?? ''}
                        onChange={(e) => onChange('school_name', e.target.value?.toUpperCase?.())}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Email Sekolah <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.school_email ?? ''}
                        onChange={(e) => onChange('school_email', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Nama Pendamping/Guru <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.teacher_name ?? ''}
                        onChange={(e) => onChange('teacher_name', e.target.value?.toUpperCase?.())}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Jumlah Pendamping/Guru <Required /></Card.Text>
                    <Form.Control
                        required
                        type='number'
                        value={payload?.teacher_count ?? ''}
                        onChange={(e) => onChange('teacher_count', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>No Handphone Pendamping/Guru <Required /></Card.Text>
                    <Form.Control
                        required
                        value={payload?.teacher_phone ?? ''}
                        onChange={(e) => onChange('teacher_phone', e.target.value)}
                    />
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Kota Domisili Sekolah <Required /></Card.Text>
                    <Form.Select
                        required
                        value={payload?.school_domicile ?? ''}
                        onChange={(e) => onChange('school_domicile', e.target.value)}
                    >
                        <option hidden value=''>Pilih kota domisili</option>
                        {Constant.domicile_address.map(option => (
                            <option value={option.value} key={`domicile_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            {payload?.school_domicile?.toLowerCase?.() === 'others' ? (
                <Card className='mb-2'>
                    <Card.Body>
                        <Card.Text>Kota Domisili Lainnya  <Required /></Card.Text>
                        <Form.Control
                            required={payload?.school_domicile.toLowerCase() === 'others'}
                            value={payload?.school_domicile_other ?? ''}
                            onChange={(e) => onChange('school_domicile_other', e.target.value?.toUpperCase?.())}
                        />
                        <span className="separator" />
                        <Form.Control.Feedback type="invalid">
                            Pertanyaan wajib diisi
                        </Form.Control.Feedback>
                    </Card.Body>
                </Card>
            ) : null}

            <Card className='mb-2'>
                <Card.Body>
                    <Card.Text>Akan Mengikuti Bible Study 2025 di Gereja Lokal? <Required /></Card.Text>
                    <Form.Select
                        required
                        defaultValue=''
                        value={payload?.event_location ?? ''}
                        onChange={(e) => onChange('event_location', e.target.value)}
                    >
                        <option hidden value=''>Pilih gereja lokal</option>
                        {Constant.event_location.map(option => (
                            <option value={option.value} key={`location_${option.value}`}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="separator" />
                    <Form.Control.Feedback type="invalid">
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Card.Body>
            </Card>

            <FormChildren
                isOutsiteOfChurch
                data={payloadChildren}
                onChange={onChangeChildren}
                onChangeRow={handleChildrenAction}
            />

            <div className='child_form_action'>
                <span>
                    Ingin menambah data untuk anak {Constant.numberDescriptions[payloadChildren.length + 1]}?
                </span>
                <Button
                    size='sm'
                    variant='secondary'
                    onClick={() => handleChildrenAction({
                        action: 'add',
                        children: new Constant.Child(),
                    })}
                >
                    Tambah
                </Button>
            </div>
        </Form.Group>
    )
}