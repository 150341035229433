import React, { useEffect, useMemo, useState } from 'react';
import { getAllIndividualRegistration } from 'datas/registration';
import { Child, event_location, payment_status, PICKUP_TYPE } from 'constants/registration';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import DataTable, { TableColumn } from 'react-data-table-component';
import { formatDate } from 'utils/format';
import { Transaction, TransactionPayment, TransactionStatus } from 'constants/transaction';
import { getAllPaymentTransaction } from 'datas/transaction';
import { CSVLink } from 'react-csv';
import { Button } from 'react-bootstrap';
import ReportIndividualExpanded from '../report-individual-expanded';

type Data = Child & Partial<Transaction>;

export const ReportIndividual = () => {
    const dataAutoReloadInterval = 30000;

    const [isLoadingTransaction, setIsLoadingTransaction] = useState(true);
    const [dataRegistration, setDataRegistration] = useState<Child[]>();
    const [dataTransaction, setDataTransaction] = useState<TransactionPayment[]>([]);
    const [filterName, setFilterName] = useState('');
    const [filterEventLocation, setFilterEventLocation] = useState('');
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<TransactionStatus>();

    const headers = [
        { label: 'No Registrasi', key: 'bill_no' },
        { label: 'Nama Orang Tua', key: 'parent_name' },
        { label: 'Email Orang Tua', key: 'parent_email' },
        { label: 'No Handphone Orang Tua', key: 'parent_phone' },
        { label: 'Domisili Orang Tua', key: 'parent_domicile' },
        { label: 'Akan mengikuti Bible Study di', key: 'event_location' },
        { label: 'Nama Lengkap Anak', key: 'name' },
        { label: 'Kelas', key: 'grade' },
        { label: 'Jenis Kelamin Anak', key: 'gender' },
        { label: 'No Handphone Anak', key: 'phone' },
        { label: 'Sudah Join CG', key: 'is_join_cg' },
        { label: 'Penjemputan Anak', key: 'pick_up_type' },
        { label: 'Jumlah Pendamping yang Mendampingi', key: 'guardian_count' },
        { label: 'Status Pembayaran', key: 'status' },
        { label: 'Tanggal Registrasi', key: 'created_at' },
        { label: 'Metode Pembayaran', key: 'payment_channel_name' },
        { label: 'Tanggal Tagihan', key: 'bill_at' },
        { label: 'Tanggal Pembayaran', key: 'paid_at' },
        { label: 'Batas Pembayaran', key: 'expired_at' },
    ];

    const columns: TableColumn<Data>[] = [
        {
            id: 'bill_no',
            name: 'No Registrasi',
            selector: row => row?.parent?.bill_no ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'name',
            name: 'Nama Lengkap Anak',
            selector: row => row?.name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'parent_name',
            name: 'Nama Orang Tua',
            selector: row => row?.parent?.parent_name ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'event_location',
            name: 'Lokasi Acara',
            selector: row => row?.parent?.event_location ?? '',
            sortable: true,
            width: '230px'
        },
        {
            id: 'pick_up_type',
            name: 'Penjemputan Anak',
            selector: row => {
                if (row.pick_up_type === PICKUP_TYPE.UNPICKED) {
                    return 'TIDAK DIJEMPUT';
                } else if (row.pick_up_type === PICKUP_TYPE.PICKEDUP) {
                    return 'DIJEMPUT';
                }
                return '-';
            },
            sortable: true,
            conditionalCellStyles: [
                { when: (row) => row?.pick_up_type === PICKUP_TYPE.UNPICKED, classNames: ['pick_up_type_success'] },
                { when: (row) => row?.pick_up_type === PICKUP_TYPE.PICKEDUP, classNames: ['pick_up_type_danger'] },
            ]
        },
        {
            id: 'id',
            name: 'Status Pembayaran',
            selector: row => row.status ?? '-',
            conditionalCellStyles: [
                { when: (row) => row?.status === TransactionStatus.PAID, classNames: ['pick_up_type_success'] },
                { when: (row) => row?.status === TransactionStatus.WAITING, classNames: ['pick_up_type_warning'] },
                { when: (row) => row?.status === TransactionStatus.EXPIRED, classNames: ['pick_up_type_danger'] },
            ]
        },
        {
            id: 'created_at',
            name: 'Tanggal Registrasi',
            selector: row => row?.parent?.created_at ? formatDate(row.parent.created_at) : '',
            sortable: true,
            width: '175px'
        },
    ];

    const handleLoadDataRegistration = async () => {
        try {
            const response = await getAllIndividualRegistration();
            if (response) {
                setDataRegistration(response);
                return true;
            }

            throw response;
        } catch (err) {
        }
    }

    const handleLoadDataTransaction = async (page?: number, existedData: TransactionPayment[] = []) => {
        const pagination = page ?? 1;

        const response = await getAllPaymentTransaction(pagination, 250);
        const newData = existedData?.concat(response);
        if (response?.length) {
            handleLoadDataTransaction(pagination + 1, newData);
        } else {
            const sortedData = newData.sort((a, b) => {
                if (b.created_at < a.created_at) {
                    return -1;
                } else {
                    return 1;
                }
            }).filter(({ bill_no }) => !!bill_no);
            setDataTransaction(sortedData);
            setIsLoadingTransaction(false);
        }
    }

    const data = useMemo<Data[]>(() => {
        if (!dataRegistration) return [];
    
        const getTransactionStatus = (bill_no: string) => {
            const transaction = dataTransaction?.find(({ bill_no: txnBillNo }) => txnBillNo === bill_no);
            return transaction || {};
        };
    
        const matchesFilters = (item: Data) => {
            const { status, parent, name } = item;
    
            if (!isLoadingTransaction && filterPaymentStatus && status !== filterPaymentStatus) return false;
            if (filterEventLocation && parent?.event_location !== filterEventLocation) return false;
            if (filterName) {
                const billNoMatch = parent?.bill_no?.toUpperCase().includes(filterName.toUpperCase());
                const parentNameMatch = parent?.parent_name?.toLowerCase().includes(filterName.toLowerCase());
                const childNameMatch = name?.toLowerCase().includes(filterName.toLowerCase());
                if (!billNoMatch && !parentNameMatch && !childNameMatch) return false;
            }
    
            return true;
        };
    
        return dataRegistration
            .map(item => {
                if (!dataTransaction?.length || !item.parent?.bill_no) {
                    return {
                        ...item.parent,
                        ...item
                    }
                }

                const transaction = getTransactionStatus(item.parent.bill_no);
                return {
                    ...transaction,
                    ...item.parent,
                    ...item,
                } as Data;
            })
            .filter(matchesFilters);
    }, [dataRegistration, dataTransaction, filterPaymentStatus, filterEventLocation, filterName]);    

    useEffect(() => {
        handleLoadDataRegistration();
        const autoReloadInterval = setInterval(() => {
            handleLoadDataRegistration();
            handleLoadDataTransaction();
        }, dataAutoReloadInterval);

        return () => {
            clearInterval(autoReloadInterval);
        }
    }, []);

    return dataRegistration ? (
        <div className='report_container_content'>
            <div className='report_filter'>
                <Form.Control
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder='Cari Nama atau No Registrasi'
                />
                <Form.Select
                    value={filterEventLocation}
                    onChange={(e) => {
                        setFilterEventLocation(e.target.value)
                    }}
                >
                    <option value=''>Cari Lokasi Acara</option>
                    {event_location.map(location => (
                        <option value={location.value} key={`option_event_location_${location.value}`}>
                            {location.label}
                        </option>
                    ))}
                </Form.Select>
                <Form.Select
                    disabled={isLoadingTransaction}
                    value={filterPaymentStatus}
                    onChange={(e) => {
                        setFilterPaymentStatus(e.target.value as TransactionStatus)
                    }}
                >
                    <option value=''>Status Pembayaran</option>
                    {payment_status.map((status) => (
                        <option value={status.value} key={`option_payment_status_${status.value}`}>
                            {status.label}
                        </option>
                    ))}
                </Form.Select>

                {isLoadingTransaction ? (
                    <Button disabled variant="success" className='d-flex align-items-center gap-2'>
                        Export Data
                        <Spinner size='sm' variant='light' />
                    </Button>
                ) : (
                    <Button variant="success">
                        <CSVLink
                            data={data}
                            headers={headers}
                            filename='transaction_bible_study_report'
                            target="_blank"
                        />
                        Export Data
                    </Button>
                )}
            </div>
            <DataTable
                keyField='id'
                data={data}
                columns={columns}
                pagination
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={ReportIndividualExpanded}
            />
        </div>
    ) : (
        <div className='d-flex align-items-center justify-content-center' style={{ height: 'calc(100vh - 10rem)' }}>
            <Spinner />
        </div>
    )
}